import labelingToolLogic from '../LabelingToolPage/LabelingToolPage.logic';
import { Tasks, TYPE_XRAY } from '../../shared-logic/enums';
import { cloneDeep, isEqual } from 'lodash';
import { isImage, isXRay } from '../../shared-logic/taskLevelsTypesHelper';
import { isTierWithPrevMarks } from '../../shared-logic/tiersHelpers';
import * as LTDC from './LabelingToolDataConstants';

const TRAINING = 'Training';
const AI = 'Ai';
const picturesMap = new Map([
  [LTDC.LOWER_IMAGE_RESPONSE, Tasks.LOWER],
  [LTDC.UPPER_IMAGE_RESPONSE, Tasks.UPPER],
  [LTDC.XRAY_IMAGE_RESPONSE, Tasks.XRAY],
]);
const statesMap = new Map([
  [LTDC.LOWER_STATE_RESPONSE, Tasks.LOWER],
  [LTDC.UPPER_STATE_RESPONSE, Tasks.UPPER],
  [LTDC.XRAY_STATE_RESPONSE, Tasks.XRAY],
]);
const notesMap = new Map([
  [LTDC.LOWER_SUPERVISOR_NOTES, Tasks.LOWER],
  [LTDC.UPPER_SUPERVISOR_NOTES, Tasks.UPPER],
  [LTDC.XRAY_SUPERVISOR_NOTES, Tasks.XRAY],
]);
const previousAggregationMap = new Map([
  [LTDC.LOWER_PREVIOUS_AGGREGATION, Tasks.LOWER],
  [LTDC.UPPER_PREVIOUS_AGGREGATION, Tasks.UPPER],
  [LTDC.XRAY_PREVIOUS_AGGREGATION, Tasks.XRAY],
]);

export const initialState = {
  task: {
    taskId: '',
    path: '',
    assignee: '',
  },
  initialOutput: {
    imagePairs: [],
    scheme: '',
  },
  pictures: [],
};

export const initStateImageLevel = {
  commited: true,
  approvedPrevTier: true,
  niri: {
    brightness: 100,
    contrast: 100,
    markings: [],
  },
  color: {
    brightness: 100,
    contrast: 100,
    markings: [],
  },
};

export const initStateXrayLevel = {
  commited: true,
  approvedPrevTier: true,
  brightness: 100,
  contrast: 100,
  markings: [],
};

export const isRejected = (obj) => obj.status === 'rejected';

export const fileName = (isTraining) => (isTraining ? TRAINING : AI);

const getUrlVars = () => {
  const params = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      params[key] = value;
    }
  );
  return params;
};

export const getUrlParam = (parameter) => {
  let urlParameter = null;
  if (window.location.href.indexOf(parameter) > -1) {
    urlParameter = getUrlVars()[parameter];
    switch (urlParameter.toLowerCase()) {
      case 'true':
        return true;
      case 'false':
        return null;
      default:
        return urlParameter;
    }
  }
  return urlParameter;
};

export const getUrlParams = () => {
  const taskId = getUrlParam('taskId');
  const assignee = getUrlParam('assignee');
  const tier = parseInt(getUrlParam('tier'));
  const imagesType = getUrlParam('type');
  const taskLevel = getUrlParam('level');
  const batch = getUrlParam('batch');
  const path = getUrlParam('path');
  const potentiallyDiscarded = getUrlParam('potentiallyDiscarded');
  return {
    taskId,
    assignee,
    tier,
    imagesType,
    taskLevel,
    batch,
    path,
    potentiallyDiscarded,
  };
};

export const retrieveSuperTaskData = async (
  path,
  assignee,
  tier,
  level,
  taskId,
  signal,
  isTraining,
  batch
) => {
  const promises = new Map();

  picturesMap.forEach((val, key) => {
    promises.set(
      key,
      labelingToolLogic.getPictures(
        `${path}/${val}/images.zip`,
        batch,
        tier,
        isXRay(val),
        signal
      )
    );
  });

  statesMap.forEach((val, key) => {
    promises.set(
      key,
      labelingToolLogic.getInitialState(
        {
          path: `${path}/${val}`,
          assignee,
          tier,
          level,
        },
        signal
      )
    );
  });

  if (isImage(level)) {
    notesMap.forEach((val, key) => {
      promises.set(
        key,
        labelingToolLogic.getSupervisorNotes(taskId, val, signal)
      );
    });
  }

  if (isTierWithPrevMarks(tier)) {
    previousAggregationMap.forEach((val, key) => {
      promises.set(
        key,
        labelingToolLogic.getPreviousAggregation(
          `${path}/${val}`,
          parseInt(tier),
          signal
        )
      );
    });
  }

  promises.set(
    LTDC.DETECTION_POINTS,
    labelingToolLogic.getDetectionPoints(
      `${path}/${isTraining ? 'reference_labels' : 'detection'}.json`,
      signal
    )
  );

  return settledPromises(promises);
};

const settledPromises = async (promises) => {
  const settledPromises = new Map();
  const results = await Promise.allSettled(Array.from(promises.values()));

  promises.forEach(function (_val, key) {
    settledPromises.set(key, results.shift());
  });

  return settledPromises;
};

const isInitialState = (taskData, type) => {
  if (type === TYPE_XRAY) {
    return taskData.imagePairs[0].markings.length === 0;
  }
  if (
    taskData.imagePairs.length === 0 ||
    (taskData.imagePairs.length === 1 &&
      taskData.imagePairs[0].niri.markings.length === 0 &&
      taskData.imagePairs[0].color.markings.length === 0)
  ) {
    return true;
  }
  return false;
};

export const setCommitAndApproved = (
  taskData,
  prevTier,
  totalPicturesInTask,
  imageTypesArray
) => {
  const isXRay = imageTypesArray[0] === TYPE_XRAY;
  prevTier && prevTier.sort((a, b) => (a.id > b.id ? 1 : -1));
  const taskDataClone = cloneDeep(taskData);

  if (!isInitialState(taskData, imageTypesArray[0])) {
    return taskDataClone;
  }

  for (let i = 0; i < totalPicturesInTask; i++) {
    const currentPairPrevMarks = prevTier.find((x) => x.id === i);
    let prevMarks = imageTypesArray
      .map((type) => currentPairPrevMarks && currentPairPrevMarks[type])
      .flat();
    prevMarks = prevMarks.filter((item) => !!item);
    const pairHasNoMarks = prevMarks.length === 0;
    const pairHasConflicts =
      prevMarks.filter((item) => !item.consistent).length > 0;

    if (!pairHasConflicts || pairHasNoMarks) {
      if (taskDataClone.imagePairs[i]) {
        taskDataClone.imagePairs[i].commited = true;
        taskDataClone.imagePairs[i].approvedPrevTier = true;
      } else {
        taskDataClone.imagePairs[i] = cloneDeep(
          isXRay ? initStateXrayLevel : initStateImageLevel
        );
        taskDataClone.imagePairs[i].id = i;
      }
    } else {
      taskDataClone.imagePairs[i] = cloneDeep(
        isXRay ? initStateXrayLevel : initStateImageLevel
      );
      taskDataClone.imagePairs[i].commited = false;
      taskDataClone.imagePairs[i].approvedPrevTier = false;
      taskDataClone.imagePairs[i].id = i;
    }
  }

  return taskDataClone;
};

export const addImageNumberToNewState = (state, images) => {
  if (
    isEqual(
      state.imagePairs,
      labelingToolLogic.initStateImageLevel.imagePairs
    ) &&
    images.length > 0
  ) {
    state.imagePairs[0].imageName = images[0].dirName;
  }
  return state;
};

export const addToothPointsToTier2 = (state, prevTierState) => {
  if (state.teeth.length === 16) {
    return state;
  } else {
    let clonedState = cloneDeep(state);
    prevTierState.forEach((tooth) => {
      const toothIndex = clonedState.teeth.findIndex((t) => t.id === tooth.id);
      if (toothIndex === -1) {
        clonedState.teeth = [
          ...clonedState.teeth,
          { id: tooth.id, markings: [], point: tooth.point },
        ];
      }
    });
    return clonedState;
  }
};
